.Recibo__Elemento-Lista a {
    color: inherit;
}

.Recibo__Elemento-Lista:hover {
    cursor: pointer;
}

.proyect-images {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    align-items: center;

    img {
        width: 40%;
        margin: 20px 0;
    }
}

@media only screen and (max-width: 700px){
    .proyect-images {
        justify-content: center;
        img {
            width: 80%;
        }
    }
}