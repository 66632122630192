body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.click-card:hover {
  cursor: pointer;
}

.Tabla__Container {
  text-align: center; }
  .Tabla__Container td {
    vertical-align: middle; }



.Recibo__Elemento-Lista a {
  color: inherit; }

.Recibo__Elemento-Lista:hover {
  cursor: pointer; }

.proyect-images {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-align-items: center;
          align-items: center; }
  .proyect-images img {
    width: 40%;
    margin: 20px 0; }

@media only screen and (max-width: 700px) {
  .proyect-images {
    -webkit-justify-content: center;
            justify-content: center; }
    .proyect-images img {
      width: 80%; } }

.Tabla__Container {
  text-align: center; }
  .Tabla__Container tr:hover {
    background-color: #dfdfdf; }
  .Tabla__Container td {
    vertical-align: middle; }

.StripeElement {
  box-sizing: border-box;
  height: 40px;
  padding: 10px 12px;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease; }

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df; }

.StripeElement--invalid {
  border-color: #fa755a; }

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important; }

