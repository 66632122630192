.Tabla__Container {
    text-align: center;

    tr:hover {
        background-color: #dfdfdf;
    }
  
    td {
        vertical-align: middle;
    }
    
  }